import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../shared/config/axios-interceptor';
import { ResponseStatus } from '../../../shared/enumeration/ResponseStatus';
import { IParams } from '../../../shared/shared-interfaces';
import { IHistory, INewHistory } from '@/shared/model/history.model';

const prefix = 'history';
// const history = 'revision';

export interface IHistoryParams extends IParams {
  status?: ResponseStatus;
  keyword?: string
  // projectId?: number; // Lấy tất cả user không phải là nhân viên của dự án được truyền vào
}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IHistoryParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IHistory[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IHistory>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewHistory, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IHistory, thunkAPI) => {
  try {
    const { historyId } = body;
    const { data } = await axios.put<IHistory>(`${prefix}/${historyId}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IUserDel {
  id: number[];
  status: ResponseStatus;
}

export const updateDeleteEntity = createAsyncThunk(
  `delete-status-${prefix}`,
  async (requestBody: IUserDel, thunkAPI) => {
    try {
      await axios.post<IHistory>(`${prefix}/status`, requestBody);
      return requestBody.id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);